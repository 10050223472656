<template>
  <div class="upload-excel">
    <a-upload v-if="!excel.file.name" :file-list="fileList" accept=".xlsx, .xls" :customRequest="uploadCustomer"
      :before-upload="beforeUpload" @change="handleChange">
      <loading-outlined v-if="loading"></loading-outlined>
      <a-button class="upload-btn" v-else>
        <Icon type="icontianjia1" />上传表格
      </a-button>
    </a-upload>

    <span class="gray-darker" v-else>
      {{excel.file.name}}
    </span>

    <a-upload class="ml3" v-if="Object.keys(excel.file).length" :file-list="fileList" :show-upload-list="false"
      accept=".xlsx, .xls" :customRequest="uploadCustomer" :before-upload="beforeUpload" @change="handleChange">
      <span class="text-12 color-primary cursor-pointer">重新选择</span>
    </a-upload>
  </div>

</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive } from "vue";
import { Upload, message } from "ant-design-vue";

import Icon from "@/components/SvgIcon";

export default defineComponent({
  name: "UploadExcel",
  props: [],
  emits: ["uploaded"],
  components: {
    AUpload: Upload,
    Icon,
  },
  setup(props, { emit }) {
    const excel = reactive({
      file: {},
    });
    const fileList = ref([]);
    const loading = ref(false);

    async function uploadCustomer(file) {
      excel.file = file.file;
      loading.value = false;

      emit("uploaded", excel.file);
    }

    function beforeUpload(file) {
      fileList.value = [];
      const index = file.name.lastIndexOf(".");
      const type = file.name.substring(index, file.name.length);

      const isExcel = [".xlsx", ".xls"].includes(_.toLower(type));

      if (!isExcel) {
        emit("uploadError", "仅支持excel文件");
      }

      return isExcel;
    }

    function handleChange({ file, fileList }) {
      if (file.status === "uploading") {
        fileList.value = fileList;
        // loading.value = true;
        return;
      }

      if (file.status === "done") {
        message.success("上传成功");
        loading.value = false;
      }

      if (file.status === "error") {
        loading.value = false;
        message.error("上传失败");
      }
    }

    return {
      excel,
      fileList,
      loading,

      uploadCustomer,
      beforeUpload,
      handleChange,

      pdfImgUrl: process.env.VUE_APP_PDF_IMAGE_URL,
    };
  },
});
</script>
<style lang='less' scoped>
.upload-excel {
  position: relative;

  .upload-btn {
    padding: 8px 16px;
    color: @gray;
    border: 1px solid @border;
    border-radius: @border-radius;
    .text-14();
    height: unset;
  }
}
</style>